<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入名称" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.device_id" placeholder="请选择设备" style="width: 140px" clearable>
        <el-option v-for="item in devices" :key="item.device_id" :label="item.device_address_and_name" :value="item.device_id"></el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.type" placeholder="请选择类型" style="width: 140px" clearable>
        <el-option label="屏幕广告" :value="1"></el-option>
        <el-option label="待机广告" :value="2"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.device ? scope.row.device.device_address_and_name : '' }}
        </template>
      </el-table-column>
      <el-table-column label="名称" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? '屏幕广告' : '待机广告' }}
        </template>
      </el-table-column>
      <el-table-column label="广告图片" min-width="100" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.url" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.order }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="280" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="70px">
        <el-form-item label="设备">
          <el-select class="filter-item" v-model="form.device_id" placeholder="请选择设备" style="width: 220px" clearable>
            <el-option v-for="item in devices" :key="item.device_id" :label="item.device_address_and_name" :value="item.device_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="图片" prop="name">
          <upload-one v-model="form.url"></upload-one>
          <div style="font-size:12px;color:red;">
            *轮播广告推荐尺寸：1080*600 | *待机广告推荐尺寸：1080*1920
          </div>
        </el-form-item>
        <el-form-item label="图片" prop="name">
          <el-select class="filter-item" v-model="form.type" placeholder="类型" style="width: 140px">
            <el-option label="屏幕广告" :value="1"></el-option>
            <el-option label="待机广告" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.order" placeholder="排序越大越前排" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        device_id: "",
        type: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        device_id: "",
        type: 1,
        name: "",
        order: 0,
        url: ""
      },
      devices: [],
    };
  },
  created() {
    this.getList();
    this.getDeviceList()
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          device_id: "",
          type: 1,
          name: "",
          order: 0,
          url: ""
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/deviceAd/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getDeviceList() {
      request({
        url: "/api/backend/device/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: this.school_id
        }
      }).then(response => {
        this.devices = response.data.data;
      });
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        device_id: item.device_id,
        type: item.type,
        name: item.name,
        order: item.order,
        url: item.url
      };
      this.dialogVisible = true;
    },
    handleDel(item) {
      this.$confirm("确定要删除该广告吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/deviceAd/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    saveData() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      } else {
        this.form.school_id = this.school_id;
      }
      if (!this.form.url) {
        this.$message({
          type: "warning",
          message: "请上传图片"
        });
        return;
      }
      if (!this.form.device_id) {
        this.$message({
          type: "warning",
          message: "请选择设备"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/deviceAd/save",
        method: "post",
        data: this.form
      }).then(() => {
        this.btnLoading = false;
        this.dialogVisible = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
